<template>
<div v-if="pageContentRefresh">
  <Breadcrumb :urls="urls" v-if="comUnmount" :translationloaded="translationloaded" :translate="translate"></Breadcrumb>
  <WhatsAppButton></WhatsAppButton>
  <div class="ps-page--shop" v-if="translationloaded==true">
    <div class="ps-container">
      
      <template v-if="search_string!=''&& search_string!=null">
         <div class="search_result mt-2">{{translate('showingsearchresultsfor')}} {{search_string}}</div>
      </template>
      
     
      <div class="ps-layout--shop-new">
        
        <a class="FilterMainBtn" id="menuIcon1" onclick="openNav2()">{{translate('filter')}}</a>

       <div class="filter_left_col">
          <div class="ps-layout__left">
          <div class="desktopSidePannelall">
            <SideBarFilter
              @price_range="setPricerange"
              :productFilters="productFilterData"
              :minPrize="filterPriceRange[0]"
              :maxPrize="filterPriceRange[1]"
               :products="productList"
              @refreshpage="pageRefresh"
              @checkedBrand="checkedBrands"
              :translationloaded="translationloaded"
            :translate="translate"
            ></SideBarFilter>
          </div>
        </div>
       </div>
        

        <div id="mySidenav2" class="filterDivMain">
          <span
            href="javascript:void(0)"
            class="closebtn"
            onclick="closeNav2()"
          >
            <img alt="" src="/assets/img/close-btn.png" />
          </span>
          <SideBarFilter
            @price_range="setPricerange"
            :productFilters="productFilterData"
            :minPrize="filterPriceRange[0]"
            :maxPrize="filterPriceRange[1]"
            :products="productList"
            @refreshpage="pageRefresh"
            @checkedBrand="checkedBrands"
            :translationloaded="translationloaded"
            :translate="translate"
          ></SideBarFilter>
        </div>
        <div class="layout__right_new mr_top25">
          <div class="ps-shopping ps-tab-root">
            <div class="ps-shopping__header">
              <p>
                <strong> {{ productCnt }} </strong> &nbsp; {{translate('Products')}}
              </p>
              <div class="ps-shopping__actions">
                <select
                  class="ps-select"
                  v-model="sort_order"
                  @change="sorProducts"
                >
                  <option value="latest">{{translate('sortByLatest')}}</option>
                  <option value="discount">{{translate('discount')}}</option>
                  <option value="lowToHigh">{{translate('priceLowToHigh')}}</option>
                  <option value="highToLow">{{translate('priceHighToLow')}}</option>
                  <option value="a-z">{{translate('sortByAToZ')}}</option>
                  <option value="z-a">{{translate('sortByZtoA')}}</option>
                </select>
              </div>
            </div>
            <div class="ps-tabs">
              <div class="ps-tab active" id="tab-1">
                <div class="ps-prduct_box">
                  <div class="rowMainAll row">
                    <div
                      class="produtDeatilsDivFloat"
                      v-for="product in productList"
                      :key="product.productId"
                    >
                    <template v-if="checkOfferExipred(product)">
                          <ProductTimer v-if="product.dealTo" :product="product"
                        :translationloaded="translationloaded"
                        :translate="translate"/>
                    </template> 
                    <template v-else>
                        <span class="endSaleBgsecTxt dummy"></span>
                    </template>
                      <ProductCard
                        :product="product"
                        :web_admin_url="web_admin_url"
                        @addToCart="addtocart($event)"
                        :cart_ajax="cart_ajax"
                        :cart_product_url_key="cart_product_url_key"
                        :currency="currency"
                         @updateCartQty="updateCart($event)" 
                        :translationloaded="translationloaded"
                        :translate="translate"
                      >
                      </ProductCard>
                    </div>
                  </div>

                  <EmptyItem
                    v-if="
                      productList &&
                        productList.length < 1 &&
                        page_ajax == 'closed'
                    "
                  ></EmptyItem>
                </div>

                <div class="w-100" >
                  <ButtonLoader
                    :loaderColor="loaderColor"
                    :loaderStyle="loaderStyle"
                    :cart_ajax="page_ajax"
                  >
                  </ButtonLoader>
                  
                </div>
                <template v-if="productList.length >4">
                   <Observer @intersect="intersect"></Observer>
                </template>
                 <template v-if="productList.length >4">
                    <span class="scrollTop" @click="topToBottom">
                          <i class="fa fa-angle-up" aria-hidden="true"></i>
                    </span>
                 </template>
              
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal" id="shop-filter-lastest" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <div class="list-group">
                <a class="list-group-item list-group-item-action" href="#"
                  >Sort by</a
                ><a class="list-group-item list-group-item-action" href="#"
                  >Sort by average rating</a
                ><a class="list-group-item list-group-item-action" href="#"
                  >Sort by latest</a
                ><a class="list-group-item list-group-item-action" href="#"
                  >Sort by price: low to high</a
                ><a class="list-group-item list-group-item-action" href="#"
                  >Sort by price: high to low</a
                ><a
                  class="list-group-item list-group-item-action text-center"
                  href="#"
                  data-dismiss="modal"
                  ><strong>Close</strong></a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
 </div> 
</template>
<script>
import Config from "@/config";
import axios from "axios";
import ProductCard from "@/components/ProductCard";
import ButtonLoader from "@/components/ButtonLoader";
import EmptyItem from "@/components/EmptyItem";
import Breadcrumb from "@/components/Breadcrumb";
import SideBarFilter from "@/components/product/SideBarFilter";
import WhatsAppButton from "@/components/WhatsAppButton";
import Observer from "@/components/Observer";
import { mapGetters,mapActions } from "vuex";
import helper from "@/helper/helper";
import $ from "jquery";
import ProductTimer from '../components/product/ProductTimer';
export default {
  name: "Products",
  components: {
    ProductCard,
    ButtonLoader,
    EmptyItem,
    Breadcrumb,
    SideBarFilter,
    Observer,
    WhatsAppButton,
    ProductTimer
  },

  props: ["url_change", "changed_url",'translationloaded',
      'translate'],
  watch: {
    // changed_url(newUrl) {
    //   this.url_key = newUrl;
    //   this.productList = [];
    //   this.currentpage = 1;
    //   this.getProductFilters();
    // },
    search_string(text) {
     
      this.productList = [];
      this.currentpage = 1;     
      this.search_status = text;
      this.getProductsList();
    },
    // sort_order() {
    //   this.productList = [];
    //   this.currentpage = 1;
    //   this.getProductsList();
    // },
    productFilterValues() {
      this.productList = [];
      this.currentpage = 1;
      this.getProductsList();
    },
    $route(value) {        
        this.url_key = value.params.category;
        this.search_string = value.query.search??'';
        this.productList = [];
        this.currentpage = 1;
        this.getProductFilters();
        helper.backtoTop();
        this.getCategoryName();
        this.fetchCategoryMetata();

        this.pageContentRefresh=false;
        this.$nextTick(() => {        
          this.pageContentRefresh = true;
        });
    },
    
    
  },
  data() {
    return {
      urls: [
        {
          path: "/",
          name: "Home",
        },
        {
          path: "/all-category",
          name: "All Categories ",
        },

        {
          path: "/products",
          name: "Seach result",
        },
      ],
      productList: [],
      web_admin_url: "",
      url_key: "",
      url_update: "",
      guest_id: "",
      cust_id: null,
      pincode: "",
      customerCart: [],
      vendor_url_key: "",
      cart_ajax: "closed",
      page_ajax: "",
      cart_product_url_key: "",
      loaderColor: "red",
      loaderStyle: {
        width: "40px",
      },
      currency: "",
      priceRange: [],
      filterPriceRange: [],
      productFilterData: [],
      selectedBrands: [],
      productFilterValues: "",
      sort_order: "latest",
      initial_loading_status: false,
      currentpage: 1,
      search_status:'',
      current_page_url:this.$route.params.category,
      comUnmount:true,
      catList:this.$store.state.category_list,
      search_string:this.$route.query.search,
      defaultMaxPrice:500000000,
      defaultMinPrice:0,
      pageContentRefresh:true
    };
  },
  mounted() {
    let $this = this;
    let parameters = this.$route.params;
    $this.url_key = parameters.category;
    $this.guest_id = localStorage.getItem("guestId");
    $this.cust_id = localStorage.getItem("custId");
    $this.vendor_url_key = Config.VENDOR_URL_KEY;
    $this.web_admin_url = Config.WEB_ADMIN_URL;
    $this.currency = Config.CURRECNCY;
    //  $this.searchString =$this.$route.query.search;
    if($this.$route.query.brand!=''){
      $this.selectedBrands.push($this.$route.query.brand);
       this.productFilterValues = this.selectedBrands.toString();
    }
    $this.getProductFilters();
    helper.backtoTop();
    $this.fetchCategoryMetata();
  },
  created() {
    this.getCategoryName();
  },
  methods: {
    ...mapActions(['storeCategoryList','storeCartSummary']),
    topToBottom(){
       helper.backtoTop();
    },
    updateCart(cartItemInfo){
     
       if(cartItemInfo.product_qty==0){
          this.rmvCartItem(cartItemInfo);
       }
       
       if(cartItemInfo.product_qty!=0){

        let guestId=null;
        let custId =null;
        custId=localStorage.getItem("custId");
        if(custId==null){
            guestId =localStorage.getItem("guestId");
        }

                let  $this =this;  
                let cartUpdateUrl ='';
                if(cartItemInfo.type =='add' ){
                    cartUpdateUrl =`${Config.BASE_URL}Order/CartItemAddQtyByUrlKey`;
                }else if(cartItemInfo.type =='sub'){       
                    cartUpdateUrl =`${Config.BASE_URL}Order/CartItemSubQtyByUrlKey`;
                }
                if(cartUpdateUrl!=''){
                     axios.post(`${cartUpdateUrl}`,{
                       "urlKey":cartItemInfo.url_key,
                        "guestId": guestId,
                        "custId":custId
                     },Config.ADMIN_API_HEADER).then(function(response){
                        if(response.status==200){
                            
                             

                               let payload ={
                                'cusId':custId,
                                'guestId':guestId,
                                'vendorUrlkey': Config.VENDOR_URL_KEY         
                                };
                                $this.$store.dispatch("getCart",payload).then(function(){                                   
                                    $this.$emit('cartUpdated',$this.cart_info);
                                    $this.storeCartSummary();
                                  
                                });
                            
                        }
                    }).catch(function(error){                       
                        $this.$toast.error(`${error.response.data.Message}`);
                         $this.qtyReset =1;
                    });
                }

       }
  
    },
    rmvCartItem(cartItemInfo){
       let $this = this;
       $this.cart_ajax = 'open';
       let guestId=null;
        let custId =null;
        custId=localStorage.getItem("custId");
        if(custId==null){
            guestId =localStorage.getItem("guestId");
        }

    
         axios.post(`${Config.BASE_URL}Order/RemoveCartItemByUrlkey`,{
                       "urlKey":cartItemInfo.url_key,
                        "guestId": guestId,
                        "custId":custId
                     },Config.ADMIN_API_HEADER).then(function(response){
                        if(response.status==200){
                             $this.cart_ajax = 'closed';                             

                               let payload ={
                                'cusId':custId,
                                'guestId':guestId,
                                'vendorUrlkey': Config.VENDOR_URL_KEY         
                                };
                                $this.$store.dispatch("getCart",payload).then(function(){                                   
                                    $this.$emit('cartUpdated',$this.cartItemInfo);
                                   // $this.storeCartSummary();
                                  
                                });
                            
                        }
                    }).catch(function(error){                       
                        $this.$toast.error(`${error.response.data.Message}`);
                         $this.qtyReset =1;
                    });
    
  },
    getCategoryName(){   
      
       
       let category='';
       let $this =this;
        
        //console.log('1'+JSON.stringify($this.urls));
       
        if(this.getCategoryList!='' && $this.url_key!=''){
             category =  this.getCategoryList.filter(function(item) {
                    if (item.catUrlKey == $this.url_key) {
                        return true;
                    }
                    return false;
                });
        }else{
          $this.storeCategoryList().then(function(){
            $this.getCategoryName();
          });
        }

        //console.log('2'+JSON.stringify($this.urls));

        if(category!=''&& this.getCategoryList!=''){
          $this.urls=[];
          $this.current_catName=category[0].catName;
          
          let parts =category[0].code.split("#");

          console.log('2'+JSON.stringify(parts));

          //console.log(JSON.stringify($this.getCategoryList));
           
           parts.forEach(function(catId){

               $this.getCategoryList.filter(function(item) {

                    
                    if (item.catId == catId) {
                         console.log(item.catId +'-'+catId+'-'+item.catName);

                         $this.urls.push({'path':`/products/${item.catUrlKey}`,'name':item.catName});

                         console.log(JSON.stringify($this.urls));
                      
                    }
                    return false;
                });

           });

          $this.urls[0]={'path':`/`,'name':'Home'};  
          //$this.urls.unshift({'path':`/`,'name':'Home'});    
          $this.comUnmount = false;
          $this.$nextTick(() => {              
                $this.comUnmount = true;
          });

          console.log('2'+JSON.stringify($this.urls));

          
        }
      
    },
    goToDetailpage(urlkey) {
      this.$router.push({ path: `/product-detail/${urlkey}` });
    },
    discountPercentage(product) {
      if (product.unitPrice > product.specialPrice) {
        let difference = product.unitPrice - product.specialPrice;

        let percentage = (difference * 100) / product.unitPrice;

        return percentage.toFixed(2);
      } else {
        return 0;
      }
    },
    getProductsList() {
      if(this.currentpage==1){
        this.productList=[];
      }
      let $this = this;
      $this.page_ajax = "open";
      axios
        .post(`${Config.BASE_URL}Product/Search`, {
          currentpage: $this.currentpage,
          custId: $this.cust_id,
          filter: { category: ($this.search_string!=''&& $this.search_string!=null?'':$this.url_key) },
          filtervalues: $this.productFilterValues,
          guestId: $this.cust_id == null ? $this.guest_id : "",
          maxPrice: ($this.$route.query.search!=null?$this.defaultMaxPrice:$this.priceRange[1]),
          minPrice:($this.$route.query.search!=null?$this.defaultMinPrice:$this.priceRange[0]) ,
          // maxPrice: ($this.priceRange[1]==''?'500000000':$this.priceRange[1]),
          // minPrice:($this.priceRange[1]==''?'0':$this.priceRange[0]) ,

          pagesize: 100,
          vendorUrlKey: $this.vendor_url_key,
          searchstring: $this.$route.query.search,
          sortorder: { direction: $this.sort_order, field: "prName" },
        },Config.ADMIN_API_HEADER)
        .then(function(response) {
          let products = response.data.Data.List;
            console.log(products);
          if (products != "") {
            // $this.productList = [...$this.productList, ...products];
            $this.productList = [...products];
          
          }

          $this.page_ajax = "closed";
          $this.initial_loading_status = true;
          $(".search_suggetions").css({ display: "none" });
        })
        .catch(function(error) {
          console.log(error);
          $this.page_ajax = "closed";
        });
    },
    addtocart(productInfo) {
      let urlKey = productInfo.url_key;
      let productQty = productInfo.product_qty;
      let optionId_ = productInfo.optionId
      let $this = this;
      $this.cart_ajax = "open";
      $this.cart_product_url_key = productInfo.url_key;
      let payload = {
        cusId: $this.cust_id != null ? $this.cust_id : "",
        guestId: $this.cust_id == null ? $this.guest_id : "",
        pincode: $this.pincode,
        urlKey: urlKey,
        productQty: productQty,
        vendorUrlkey: $this.vendor_url_key,
        optionId :optionId_,
      };

      $this.$store
        .dispatch("addToCart", { payload: payload, toast: $this.$toast })
        .then(function() {
          // console.log(response);
          $this.cart_ajax = "closed";
          $this.cart_product_url_key = "";
          $this.customerCart = $this.$store.state.customer_cart;
          $this.$emit("cartUpdated", $this.customerCart);
        });
    },
    setPricerange(price) {
      this.priceRange = price;
      if (price[1] != undefined) {
        this.defaultMaxPrice = this.priceRange[1];
        this.defaultMinPrice = this.priceRange[0]
        this.productList = [];
        this.currentpage = 1;
        this.getProductsList();
      }
    },
    getProductFilters() {
      let $this = this;
      $this.page_ajax = "open";
      axios
        .post(
          `${Config.BASE_URL}Product/SearchFilter`,
          {
            currentpage: $this.currentpage,
            custId: $this.cust_id,
            filter: { category: $this.url_key },
            filtervalues: "",
            guestId: $this.cust_id == null ? $this.guest_id : "",
            maxPrice: $this.priceRange[1],
            minPrice: $this.priceRange[0],
            pagesize: 100,
            vendorUrlKey: $this.vendor_url_key,
            searchstring: $this.$route.query.search,
            sortorder: { direction: "asc", field: "" },
          },
          Config.ADMIN_API_HEADER
        )
        .then(function(response) {
          if (response.status == "200") {
            if($this.$route.query.search!=''){
                $this.defaultMaxPrice=response.data.Data.maxPrize?? $this.defaultMaxPrice;
                $this.defaultMinPrice =response.data.Data.minPrize??$this.defaultMinPrice;
            }
            $this.filterPriceRange = [
              response.data.Data.minPrize != ""
                ? response.data.Data.minPrize
                : 0,
              response.data.Data.maxPrize != ""
                ? response.data.Data.maxPrize
                : 500,
            ];
            $this.priceRange = [
              response.data.Data.minPrize != ""
                ? response.data.Data.minPrize
                : 0,
              response.data.Data.maxPrize != ""
                ? response.data.Data.maxPrize
                : 500,
            ];

            $this.productFilterData = response.data.Data;
            $this.currentpage = 1;
            $this.getProductsList();
          }
        });
    },
    pageRefresh(url_key) {
      this.$emit("refreshpage", url_key);
    },
    checkedBrands(checkedBrands) {     
      this.selectedBrands = checkedBrands;      
      this.productFilterValues = this.selectedBrands.toString();
    },
    sorProducts() {
      event.preventDefault();
      this.productList = [];
      this.currentpage = 1;
      this.getProductsList();
    },
    intersect() {
      if (this.initial_loading_status == true) {
        this.currentpage = parseInt(this.currentpage) + 1;
        if (this.productList.length > 0) {
          this.getProductsList();
        }
      }
    },
    fetchCategoryMetata(){

      let categoryList =this.$store.state.category_list;
       let currentCatUrlkey = this.$route.params.category;
      categoryList.forEach(function(item){
         if(item.catUrlKey== currentCatUrlkey){
          // console.log(item);
           if(item.metaTitle!=''){
              item.metaTitle?document.title = item.metaTitle:'';
              item.shortDescription?$('meta[name=description]').attr('content', item.shortDescription):'';
              item.metaKeywords?$('meta[name=keywords]').attr('content', item.metaKeywords):'';
           }
            
         }
      });
      


    },
    checkOfferExipred(product){
      
      let d1 = new Date(product.dealTo)
      let d2 = new Date(new Date().toLocaleString("en-US", {timeZone: "Asia/Muscat"}))
      if(d1>=d2){
          return true;
      }else{
          return false;
      }
  }
    
  },
  computed: {
    ...mapGetters(["getSearchString","getCategoryList"]),
    productCnt() {
      let length = 0;
      this.productList.forEach(function(item, index) {
        if (index == 0) {
          length = item.rc;
        }
      });

      return length;
    },
  },
};
</script>

<style scopped>

.ps-product .ps-product__title {
  height: 30px;
}
.out_stock {
  text-align: center;
  color: #e81111;
  font-size: 16px;
  font-weight: bold;
  padding: 6px; height: 30px;
}
.ps-prduct_box {
  min-height: 100px;
}

.ps-layout--shop-new{
  float: left;
  position: relative;
  width: 100%;
}

.ps-layout--shop-new .ps-layout__left{ 
    z-index: 99;
    background: #f1f1f1;
    margin-top: 25px;
   
    border: 1px solid #ddd;
    padding-bottom: 50px;
    top:139px;
   
}
.filter_left_col{
   width:25%;
   float: left;  
   width: 25%;
    float: left;
    padding-right: 20px;
    position: sticky;
    top: 116px; 
}

.desktopSidePannelall .widget_shop{margin-bottom: 0px; padding: 13px 20px 0px;}
.ps-list--categories li {
position: relative;
padding: 3px 0px 3px 10px;
font-size: 15px;
font-weight: normal;
}

.layout__right_new{
  
    float: left!important;
    width: 75%!important;
}


.subscraibeBGSec{
  float: left;
  width: 100%;
}

.ps-page--shop{
  float: left;
  width: 100%;
  padding-bottom: 50px;
}

.endSaleBgsecTxt.dummy{
  background: transparent!important;
  min-height: 24px!important;
}



.ps-select {
  line-height: 20px;
  padding: 10px 20px;
  border: 0;
}
@media screen and (max-width: 570px){
  .produtDeatilsDivFloat {
      width: 50%;
  }
}
@media screen and (max-width: 1200px){
    .filter_left_col{
      display: none!important;
    }
    .layout__right_new{
      width: 100%!important;
      padding-top: 34px
    }
}

</style>
